import React from "react"
import { graphql, Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const AboutServicesPageTemplate = ({ data }) => {
  const {
    imageJakesCafe,
    imageMagentoCommunity,
    imageMagentoCertified,
    imageMagentoFrontEndDev,
    imageProfessionalDev,
    imageDeveloperPlus,
    imageBrandedEcom,
    imageWebsitesDigital,
    imageBrandStrategy,
    page,
  } = data

  return (
    <Layout>
      <Seo seoData={page.seo} uri={page.uri} />

      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <h1 className="headline">Challenge the Competition.</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid" id="about-commerce">
        <div className="row position-relative">
          <div className="col-lg-6">
            <GatsbyImage fluid={imageJakesCafe?.childImageSharp?.fluid} />
          </div>
          <section id="magento-certified">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="text-center text-md-left d-md-flex align-items-center">
                    <div className="d-block d-md-inline-block certificate-img">
                      <GatsbyImage
                        className=""
                        fixed={imageMagentoCommunity?.childImageSharp?.fixed}
                        alt="We are Magento Partners"
                      />
                    </div>
                    <GatsbyImage
                      className="certificate-img"
                      objectFit="contain"
                      fixed={imageMagentoCertified?.childImageSharp?.fixed}
                      alt="FindCanary is a Magento 2 certified agency"
                    />
                    <GatsbyImage
                      className="certificate-img"
                      objectFit="contain"
                      fixed={imageMagentoFrontEndDev?.childImageSharp?.fixed}
                      alt="We have earned the Magento 2 Front-end certification"
                    />
                    <GatsbyImage
                      className="certificate-img"
                      objectFit="contain"
                      fixed={imageProfessionalDev?.childImageSharp?.fixed}
                      alt="Our Backend developers earned the professisonal certification"
                    />
                    <GatsbyImage
                      className="certificate-img"
                      objectFit="contain"
                      fixed={imageDeveloperPlus?.childImageSharp?.fixed}
                      alt="We still think Magento 1 is important"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="col-lg-6">
            <p>
              Commerce Canary was founded out of the desire to create an
              e-commerce agency that stood as your partner to celebrate great
              times and help pick you back up when they are not. Through
              everything we do, we believe in striving to empower individuals to
              celebrate success, and we believe in inspiring change.{" "}
            </p>
            <p>
              Change doesn’t come without questions or challenges, but through
              quality partnerships and a passion for technology, Find Canary
              paves the way for what e-commerce can become. Through our use of
              Magento and e-commerce expertise, we help our customers as their
              partners in business.
            </p>
          </div>
        </div>
      </div>
      <section id="digital-solutions" className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <span>
              As a strategic partner we craft digital-first brand programs,
              interactive experiences, and eCommerce flagships that
              differentiate and resonate in today’s consumer-centric, ever
              changing business environment.
            </span>
            <span className="strong">Digital Solutions.</span>
          </div>
        </div>
      </section>
      <section className="services-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 m-auto">
              <div className="row">
                <div className="col-md-5">
                  <GatsbyImage
                    className="lazy"
                    fluid={imageBrandedEcom?.childImageSharp?.fluid}
                    alt=""
                  />
                </div>
                <div className="col-md-7 pr-3 pr-md-5 pl-3 pl-md-5">
                  <h3>Branded eCommerce</h3>
                  <p>
                    Our eCommerce design starts and ends with a best-in-class
                    experience strategy that builds brands and drives
                    transactions.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <GatsbyImage
                    className="lazy"
                    fluid={imageWebsitesDigital?.childImageSharp?.fluid}
                    alt=""
                  />
                </div>
                <div className="col-md-7 pr-3 pr-md-5 pl-3 pl-md-5">
                  <h3>Websites & Digital Platforms</h3>
                  <p>
                    Through a deep understanding of our audience’s makeup and
                    motivations, we design digital experiences that connect with
                    people.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <GatsbyImage
                    className="lazy"
                    fluid={imageBrandStrategy?.childImageSharp?.fluid}
                    alt=""
                  />
                </div>
                <div className="col-md-7 pr-3 pr-md-5 pl-3 pl-md-5">
                  <h3>Brand Strategy & Identity</h3>
                  <p>
                    We build and activate brands through cultural insight,
                    strategic vision, and the power of emotion across every
                    element of its expression.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="white-cta">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 text-center text-lg-left">
              <span>
                Start your ecommerce journey, and find reasons for us to help.
              </span>
              <div className="d-md-block d-flex align-items-center justify-content-center">
                <Link to="/contact/" className="btn btn-cta on-lights">
                  Get In Touch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutServicesPageTemplate

export const query = graphql`
  query AboutServicesPage($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      uri
      seo {
        ...SeoData
      }
    }
    imageJakesCafe: file(name: { eq: "jakes-cafe" }) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageMagentoCommunity: file(
      name: { eq: "magento_community_insider_program" }
    ) {
      childImageSharp {
        fixed(quality: 92, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    imageMagentoCertified: file(name: { eq: "magento_2_certified" }) {
      childImageSharp {
        fixed(quality: 92, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    imageMagentoFrontEndDev: file(
      name: { eq: "magento-2-front-end-developer" }
    ) {
      childImageSharp {
        fixed(quality: 92, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    imageProfessionalDev: file(
      name: { eq: "badge-professional-m2-developer" }
    ) {
      childImageSharp {
        fixed(quality: 92, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    imageDeveloperPlus: file(name: { eq: "badge-developer-plus" }) {
      childImageSharp {
        fixed(quality: 92, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    imageBrandedEcom: file(name: { eq: "branded-ecom" }) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageWebsitesDigital: file(name: { eq: "websites-digital-platforms" }) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageBrandStrategy: file(name: { eq: "brand-strategy-identity" }) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
